@import 'bootstrap.scss';

/* Styles */
.navbar{
  background-color:#1B1C1D !important;
  max-height: 500px !important;
  height: 93px !important;
  font-size: 20px;
}

.footer{
  height: 200px;
  color:#FFF;
  background-color:#1B1C1D;
  padding:20px;
  padding-top:40px;
}

.footer .social a{
  color:#FFF
}

.footer-social-icon{
  color: white;
  padding-left: 7px;
  padding-right: 7px;
}

.center{
  text-align: center;
}

pre code {
  line-height: 1.6em;
  font-size: 13px;
}
pre {
  padding: 0.1em 0.5em 0.3em 0.7em;
  border-left: 11px solid #ccc;
  margin: 1.7em 0 1.7em 0.3em;
  overflow: auto;
  width: 93%;
}
/* target IE7 and IE6 */
*:first-child+html pre {
  padding-bottom: 2em;
  overflow-y: hidden;
  overflow: visible;
  overflow-x: auto;
}
* html pre {
  padding-bottom: 2em;
  overflow: visible;
  overflow-x: auto;
}

.grayscale {
  filter: gray; /* IE6-9 */
  -webkit-filter: grayscale(97%); /* Chrome 19+ & Safari 6+ */
}

.grayscale:hover {
  filter: none;
  -webkit-filter: grayscale(0%);
}

.navbar{
  height: 60px!important;
}
